import './About.css';



const About = () => (
    <>
        <div className='photo-container'>
            <img className='photo' src='https://cdn.krystofprasil.cz/krystofprasil.cz/profile.jpg' alt='můj obrázek'></img>
            <h1>Kryštof Prášil</h1>  
        </div>
        <div className='about'>
            <h1>O mně</h1>
            <p>Jsem {Math.floor((new Date().getTime() - new Date(2004, 3, 8).getTime()) / 31556952000)} letý programátor webových aplikací. Pracuji s PHP a JavaScriptem, v PHP s frameworky NETTE a Slim a v JavaScriptu dávám přednost Vue.js. Jsem zaměřen převážně na backend v objektově orientovaném
            PHP. Mám také zkušenosti s CMS Wordpress, programovacím jazykem Python a programováním mikrokontrolerů Arduino. Velmi se přátelím s Dockerem a mám v oblibě kontejnerizovat vše co jde. Umím anglicky na pokročilé úrovni, více než dostačující ke čtení dokumentací a komunikaci téměř o čemkoliv.
            Také umím pracovat s databázovými systémy MySQL a Oracle. Nedělá mi problém ani ovládání a správa linuxových serverů.
            </p>
        </div>
    </>
    
)

export default About;
