import './Form.css'
import React, { Component } from 'react'
import { $ }  from 'react-jquery-plugin'

export class Form extends Component {
        
    submitHandler = (e) => {
        e.preventDefault();
            var dataFromForm  = $("#send").serialize();
    

            var available = false;
            const timePrev = window.localStorage && window.localStorage.getItem('timeout');
            if (timePrev) {
                const timeNow = Math.round(new Date().getTime() / 1000);
                if (timeNow - timePrev >= 300){
                    available = true;
                }else{
                    available = false;
                    document.getElementById('message').innerHTML = '<span class="fail"> Z důvodu prevence proti spamu, musíte po zaslání zprávy poslat nejméně 5 minut před posláním další</span>';
                }
            }else{
                available = true;
            }

            
            /* if (window.localStorage){
                if (localStorage.getItem('timeout')){
                    const timePrev = localStorage.getItem('timeout');
                    const timeNow = Math.round((new Date()).getTime() / 1000);
                    if ((timeNow - timePrev) >= 300){
                        var available = true;
                    }else {
                        var available = false;
                        document.getElementById('message').innerHTML = '<span class="fail"> Z důvodu prevence proti spamu, musíte po zaslání zprávy poslat nejméně 5 minut před posláním další</span>';
                    }
                }else {
                    var available = true;
                }
            }else {
                var available = true;
            } */
            if (available){
                $.post("mail.php",dataFromForm, function(data){
                    document.getElementById('message').innerHTML = '';
                
                    
                    console.log(data);
                    data = JSON.parse(data);
                    console.log(data);
                    if (data.statusBool === true){
                    document.getElementById('message').innerHTML = '<span class="success">' + data.status + '</span>';
                    document.getElementById('name').value = '';
                    document.getElementById('email').value = '';
                    document.getElementById('msg').value = '';
        
                    localStorage.setItem('timeout', Math.round((new Date()).getTime() / 1000));
                    }else {
                        document.getElementById('message').innerHTML = '<span class="fail">' + data.status + '</span>';
                    }
                
                    
                });
            } 
            

            setTimeout(function () {
                document.getElementById('message').innerHTML = '';
            }, 3000);
        
    }
    
    render() {

        return (
            <div>
                <div className='social-container'>
                    <a href='https://www.instagram.com/krystofprasil/' target='_blank' rel='noreferrer'> 
                        <div className='icon-background'>
                            <div className='instagram icon'></div>
                        </div>
                    </a>
                    <a href='https://twitter.com/prasil_krystof' target='_blank' rel='noreferrer'> 
                        <div className='icon-background-twitter'>
                            <div className='twitter icon'></div>
                        </div>
                    </a>
                    <a href='https://www.linkedin.com/in/kryštof-prášil-a7356b20b/' target='_blank' rel='noreferrer'>
                        <div className='icon-background-linkedin'>
                            <div className='linkedin icon'></div>
                        </div>
                    </a>

                    <a href='mailto:krystofprasil18@gmail.com'> 
                        <div className='icon-background-mail'>
                            <div className='mail icon'></div>
                        </div>
                    </a>
                </div>
            </div>
        )
    }
}

export default Form
