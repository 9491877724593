import React, { useRef, useEffect, useState } from "react";
import './App.css';
import About from './about/About.js';
import Form from './form/Form.js';
import Technologies from './technologies/Technologies.js';
//import Projects from './projects/Projects.js';


const getDimensions = ele => {
  const { height } = ele.getBoundingClientRect();
  const offsetTop = ele.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

const scrollTo = ele => {
  if (ele.className === 'about-wraper') {
    ele.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  } else {
    ele.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
  
};

function App() {
  const [visibleSection, setVisibleSection] = useState();

  const headerRef = useRef(null);
  const aboutRef = useRef(null);
  const technologiesRef = useRef(null);
  const projectsRef = useRef(null);
  const contactRef = useRef(null);
  // calculate current year for footer
  const year = new Date().getFullYear();
  const sectionRefs = [
    { section: "About", ref: aboutRef },
    { section: "Technologies", ref: technologiesRef },
    { section: "Projects", ref: projectsRef },
    { section: "Contact", ref: contactRef },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const { height: headerHeight } = getDimensions(headerRef.current);
      const scrollPosition = window.scrollY + headerHeight;
      // eslint-disable-next-line
      const selected = sectionRefs.find(({ section, ref }) => {
        const ele = ref.current;
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele);
          return scrollPosition > offsetTop && scrollPosition < offsetBottom;
        }
      });

      if (selected && selected.section !== visibleSection) {
        setVisibleSection(selected.section);
      } else if (!selected && visibleSection) {
        setVisibleSection(undefined);
      }
    };

    handleScroll();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    // eslint-disable-next-line
  }, [visibleSection]);

  return (
    <div className="App">
      <div className="content">
        <div className="sticky">
          <div className="header" ref={headerRef}>
            <button
              type="button"
              className={`header_link ${visibleSection === "About" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(aboutRef.current);
              }}
            >
              O mně
            </button>
            <button
              type="button"
              className={`header_link ${visibleSection === "Technologies" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(technologiesRef.current);
              }}
            >
              Technologie
            </button>
            {/* <button
              type="button"
              className={`header_link ${visibleSection === "Projects" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(projectsRef.current);
              }}
            >
              Mé projekty
            </button> */}
            <button
              type="button"
              className={`header_link ${visibleSection === "Contact" ? "selected" : ""}`}
              onClick={() => {
                scrollTo(contactRef.current);
              }}
            >
              Kontaktujte mě
            </button>
          </div>
        </div>
        
        <div className='background'>
          <div className='about-wraper' id="about" ref={aboutRef}>
            <About></About>
          </div>
            

          
          <div className='technologies' id="technologies" ref={technologiesRef}>
            <Technologies></Technologies>
          </div>
          {/* <div className='projects'  ref={projectsRef}>
            <Projects></Projects>
          </div> */}
          <div className="contact" id="contact" ref={contactRef}>
            <Form></Form>
          </div>
          <div className ='footer'><span>krystofprasil.cz | © {year} Kryštof Prášil</span></div>
        </div>
      

      </div>

      
    </div>
  );
}

export default App;