import './Technologies.css';

const Technologies = () => (
    <>
        <h1>Technologie se kterými pracuji</h1>
        <div className='wp'>
            <p>Wordpress</p>
        </div>
        <div className='vue'>
            <p>Vue.js</p>
        </div>
        <div className='docker'></div>
        <div className='nette'></div>
        <div className='slim'></div>
        <div className='oracle'></div>
        <div className='mysql'></div>
    </>

)

export default Technologies;
